import DOMPurify from 'dompurify';
import hljs from 'highlight.js/lib/core';
import c from 'highlight.js/lib/languages/c';
import cpp from 'highlight.js/lib/languages/cpp';
import csharp from 'highlight.js/lib/languages/csharp';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import markdown from 'highlight.js/lib/languages/markdown';
import python from 'highlight.js/lib/languages/python';
import typescript from 'highlight.js/lib/languages/typescript';
import 'highlight.js/styles/xcode.css';
import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';
import * as UIUtilsTemplate from 'soy/commons/UIUtilsTemplate.soy.generated';
import type { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import type { HtmlSanitizer } from 'ts-closure-library/lib/html/sanitizer/htmlsanitizer';
import { Builder } from 'ts-closure-library/lib/html/sanitizer/htmlsanitizer';
import * as unsafe from 'ts-closure-library/lib/html/sanitizer/unsafe';
import { Const } from 'ts-closure-library/lib/string/const';
import * as style from 'ts-closure-library/lib/style/style';
import { AdvancedTooltip } from 'ts-closure-library/lib/ui/advancedtooltip';
import * as soy from 'ts/base/soy/SoyRenderer';
import { StringUtils } from 'ts/commons/StringUtils';

hljs.registerLanguage('c', c);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('python', python);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('typescript', typescript);
hljs.registerLanguage('markdown', markdown);
hljs.registerLanguage('java', java);

/** Wrapper methods for the remarkable library (markup rendering). https://github.com/jonschlinkert/remarkable */
export class RemarkableUtils {
	/** Remarkable Renderer with standard options. can't specify the type more precisely since it comes from the library */
	private static STANDARD_RENDERER_INSTANCE: Remarkable | null = null;

	/** Remarkable Renderer that allows HTML in the source string. */
	private static ALLOW_HTML_RENDERER_INSTANCE: Remarkable | null = null;

	/**
	 * Remarkable Renderer that allows HTML in the source string and syntax highlighting in the rendered code.
	 *
	 * We need a special renderer for finding messages since we want to highlight code in some cases. This is useful in
	 * particular for Findbugs Finding messages. All Findbugs messages are available in findbugs.jar/messages.xml.
	 *
	 * In addition to allowing HTML, we use a code highlighter for code blocks (the ``` markup annotation).
	 */
	private static FINDING_RENDERER: Remarkable | null = null;

	private static MARKDOWN_RENDERER: Remarkable | null = null;

	/** Markup code for the syntax-help tooltip. */
	private static readonly REMARKABLE_SYNTAX_FOR_TOOLTIP =
		'|     Input     | Rendered           |\n' +
		'| :--: | --: |\n' +
		'| \\*\\*Bold\\*\\* or    \\_\\_Bold\\_\\_ \t|   **Bold**    |\n' +
		'| \\*Italic\\* or \\_Italic\\_ \t|   *Italic*    |\n' +
		'| \\~\\~strikethrough\\~\\~ | ~~strikethrough~~ |\n' +
		'| \\[Link\\]\\(http://www.teamscale.com\\) | [Link](http://www.teamscale.com) |\n' +
		'|  (URLs are linked automatically)       | http://teamscale.com  |\n' +
		'| \\!\\[Image\\]\\(http://... .png\\) | ![Image](images/teamscale-logo.svg) |\n' +
		'| \\`inline code\\` (backticks) | `inline code` (backticks)|\n' +
		'|\\( c \\)  \\( TM \\)  \\( R \\)  \\+ \\-  \\( p \\)|(c) (TM) (R) +- (p)|\n' +
		'\n' +
		'* List item (put \\* or \\- before list items)\n' +
		'\n' +
		'1. Numbered list item (put `1.`, `2.`,... (or `1)`,`2)`,...) before list items)\n' +
		'\n' +
		'Horizontal Rule (start line with \\*** or \\-\\-\\-)\n' +
		'***\n' +
		'> Blockquote (start line with \\>)\n' +
		'\n' +
		'# Heading 1 (start line with \\#)\n' +
		'## Heading 2 (start line with \\#\\#)\n' +
		'\n' +
		'We support most syntax elements of [commonmark](http://commonmark.org/help/) and [GitHub Flavored Markdown](https://help.github.com/articles/about-writing-and-formatting-on-github/).\n' +
		'\n' +
		"We use the rendering engine '**remarkable**' [(Github)](https://github.com/jonschlinkert/remarkable/).";

	/**
	 * List of all HTML tags that we want to allow being embedded in Markdown. This does not include tags like
	 * `<script>` because it could cause security issues.
	 */
	private static readonly HEADINGS_TAG_WHITELIST = [
		'h1',
		'h2',
		'h3',
		'h4',
		'h5',
		'h6',
		'p',
		'br',
		'pre',
		// Paragraphs
		'b',
		'strong',
		'i',
		'em',
		'mark',
		'small',
		'del',
		'ins',
		'sub',
		'sup',
		// Formatting tags
		'q',
		'cite',
		'blockquote',
		// Quotation
		'a',
		'img',
		// Links
		'table',
		'thead',
		'tbody',
		'th',
		'tr',
		'td',
		// Tables
		'ul',
		'ol',
		'li',
		'dl',
		'dt',
		'dd',
		// Lists
		'div',
		'span',
		// Grouping
		'code',
		'kbd',
		'samp',
		// Computer code
		'hr'
	];

	/**
	 * Sanitizer that allows (a whitelist of) formatting tags (which might be generated by remarkable or might be
	 * contained in html mixed in the markup text). This sanitizer removes "dangerous" tags such as <script>. We allow
	 * class names as they are needed by our code syntax highlighter (Highlight JS)
	 */
	public static REMARKABLE_SANITIZER: HtmlSanitizer =
		//Horizontal rule
		(function (): Builder {
			const builder = new Builder();
			return unsafe
				.alsoAllowAttributes(
					Const.from("Allow the src attribute of img tags (otherwise, images won't work"),
					builder,
					[
						{
							tagName: 'img',
							attributeName: 'src',
							policy: null
						},
						{ tagName: '*', attributeName: 'class', policy: null }
					]
				)
				.onlyAllowTags(RemarkableUtils.HEADINGS_TAG_WHITELIST);
		})().build();

	/**
	 * Syntax-highlights the given string using the HighlightJS library.
	 *
	 * @param language If not given, an language-independent auto-highlighting will be performed
	 */
	private static highlightSyntaxInString(input: string, language?: string | null): string {
		if (language != null && hljs.getLanguage(language)) {
			try {
				return hljs.highlight(input, { language }).value;
			} catch (err) {
				console.error(err);
			}
		}
		try {
			return hljs.highlightAuto(input).value;
		} catch (err) {
			console.error(err);
		}
		// Use external default escaping
		return '';
	}

	/**
	 * Wrapper for the remarkable render method. Allows to override the default rendering settings and removes
	 * unnecessary<p> tags added by remarkable.
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML.
	 */
	private static renderWithRenderer(markupString: string | null, renderer: Remarkable): string {
		if (markupString == null) {
			return '';
		}
		let renderedText = renderer.render(markupString, {});

		// Remarkable sometimes adds unnecessary linebreaks and paragraph tags which lead to empty lines in tooltips
		renderedText = renderedText.trim();
		if (renderedText.startsWith('<p>') && renderedText.endsWith('</p>')) {
			renderedText = StringUtils.stripPrefix(renderedText, '<p>');
			renderedText = StringUtils.stripSuffix(renderedText, '</p>');
		}
		return renderedText || '';
	}

	/**
	 * Wrapper for the main render method with default parameters (escapes HTML tags).
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML.
	 */
	public static renderNonHtml(markupString: string | null): string {
		return RemarkableUtils.renderWithRenderer(markupString, RemarkableUtils.getStandardRenderer());
	}

	/**
	 * Wrapper for the main render method. HTML input is allowed to pass the markup rendering. Afterwards a HTML
	 * sanitizer removes any non-formatting tags. Details: {@link REMARKABLE_SANITIZER}
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderAllowHtml(markupString: string | null): SafeHtml {
		const rendered = RemarkableUtils.renderWithRenderer(markupString, RemarkableUtils.getAllowHtmlRenderer());
		return RemarkableUtils.REMARKABLE_SANITIZER.sanitize(rendered);
	}

	/**
	 * Takes markdown and returns a sanitized HTML string. HTML input is allowed to pass the markup rendering.
	 * Afterwards an HTML sanitizer removes any non-formatting tags. The dompurify package is used for sanitization, so
	 * the output is a string that can be dropped into the DOM without risking XSS attacks. The {@link SafeHtml} class
	 * from Closure library used in {@link renderAllowHtml} above cannot be used with React.
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderToSanitizedHtml(markupString: string | null, renderer: Remarkable): string {
		const rendered = RemarkableUtils.renderWithRenderer(markupString, renderer);
		return DOMPurify.sanitize(rendered);
	}

	/**
	 * Takes markdown and returns a sanitized string without any HTML tags. HTML input is allowed to pass the markup
	 * rendering. Afterwards an HTML sanitizer removes any tags. The sanitize-html package is used for sanitized, so the
	 * output is a string that can be used with React. The {@link SafeHtml} class from Closure library used in
	 * {@link renderAllowHtml} above cannot be used with React.
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderToSanitizedText(markupString: string | null, renderer: Remarkable): string {
		const rendered = RemarkableUtils.renderWithRenderer(markupString, renderer);
		return DOMPurify.sanitize(rendered, { ALLOWED_TAGS: [] });
	}

	/**
	 * Wrapper to render a finding message or description. HTML input is allowed to pass the markup rendering.
	 * Afterwards an HTML sanitizer removes any non-formatting tags. Details: {@link REMARKABLE_SANITIZER}
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderFindingMessage(markupString: string | null): SafeHtml {
		const rendered = RemarkableUtils.renderWithRenderer(markupString, RemarkableUtils.getFindingMessageRenderer());
		return RemarkableUtils.REMARKABLE_SANITIZER.sanitize(rendered);
	}

	/**
	 * Wrapper to render a finding message or description. HTML input is allowed to pass the markup rendering.
	 * Afterwards a HTML sanitizer removes any non-formatting tags. Details: {@link REMARKABLE_SANITIZER}
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderFindingDescription(markupString: string | null): SafeHtml {
		let rendered = RemarkableUtils.renderWithRenderer(
			markupString,
			RemarkableUtils.getFindingDescriptionRenderer()
		);
		if (rendered === markupString) {
			rendered = '<div>' + rendered + '</div>';
		}
		return RemarkableUtils.REMARKABLE_SANITIZER.sanitize(rendered);
	}

	/**
	 * Wrapper to render markup with code. HTML input is allowed to pass the markup rendering. Afterwards a HTML
	 * sanitizer removes any non-formatting tags. Details: {@link REMARKABLE_SANITIZER}
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderMarkdown(markupString: string | null): SafeHtml {
		let rendered = RemarkableUtils.renderWithRenderer(markupString, RemarkableUtils.getMarkdownRenderer());
		if (rendered === markupString) {
			rendered = '<div>' + rendered + '</div>';
		}
		return RemarkableUtils.REMARKABLE_SANITIZER.sanitize(rendered);
	}

	/**
	 * Renders the given markup string as sanitized html. HTML input is escaped by the markup rendering engine.
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderAsSafeHtml(markupString: string | null): SafeHtml {
		return RemarkableUtils.REMARKABLE_SANITIZER.sanitize(RemarkableUtils.renderNonHtml(markupString));
	}

	/**
	 * Attaches a help tooltip with the markup formatting syntax help to the given element.
	 *
	 * @param element Element to become the tooltip.
	 */
	public static attachSyntaxAdvancedTooltip(element: Element | null): void {
		if (!element) {
			return;
		}
		const tooltip = new AdvancedTooltip();
		const renderedSyntaxHelp = RemarkableUtils.renderAsSafeHtml(RemarkableUtils.REMARKABLE_SYNTAX_FOR_TOOLTIP);
		const tooltipInsideElement = soy.renderAsElement(UIUtilsTemplate.divWithIdWrapper, {
			id: 'syntaxTooltip',
			content: renderedSyntaxHelp
		});
		tooltip.setElement(tooltipInsideElement);
		style.setStyle(tooltip.getElement(), { 'z-index': '10000', position: 'absolute', display: 'none' });
		tooltip.attach(element);
	}

	/** @returns The default Remarkable renderer, which e.g. disallows HTML and auto-converts link in the original input */
	private static getStandardRenderer(): Remarkable {
		if (RemarkableUtils.STANDARD_RENDERER_INSTANCE !== null) {
			return RemarkableUtils.STANDARD_RENDERER_INSTANCE;
		}
		RemarkableUtils.STANDARD_RENDERER_INSTANCE =
			// Default behavior: don't highlight
			// autoconvert URL-like texts to links
			new Remarkable('full', {
				html: false,
				// Enable HTML tags in source
				xhtmlOut: false,
				// Use '/' to close single tags (&lt;br
				// /&gt;)
				breaks: false,
				// Don't convert '\n' in paragraphs into
				// &lt;br&gt; (GitHub does not do it)
				langPrefix: 'language-',
				// CSS language prefix for fenced blocks
				linkTarget: '',
				// Set target to open link in new tab
				typographer: true,
				// Enable some language-neutral replacements + quotes beautification
				quotes: '\u201c\u201d\u2018\u2019',
				// Double + single quotes replacement pairs, when typographer enabled,
				// and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
				highlight: (): string => {
					// We would need the highlight.js library to use this
					return '';
				}
				// Highlighter function. Should return escaped HTML,
				// or '' if input not changed
			}).use(linkify);
		return RemarkableUtils.STANDARD_RENDERER_INSTANCE;
	}

	/** Markdown renderer that allows HTML. */
	public static getAllowHtmlRenderer(): Remarkable {
		if (RemarkableUtils.ALLOW_HTML_RENDERER_INSTANCE !== null) {
			return RemarkableUtils.ALLOW_HTML_RENDERER_INSTANCE;
		}
		RemarkableUtils.ALLOW_HTML_RENDERER_INSTANCE =
			// Autoconvert URL-like texts to links
			new Remarkable('full', {
				html: true,
				// Enable HTML tags in source
				xhtmlOut: false,
				// Use '/' to close single tags (&lt;br
				// /&gt;)
				breaks: true,
				// Convert '\n' in paragraphs into
				// &lt;br&gt;
				langPrefix: 'language-',
				// CSS language prefix for fenced blocks
				linkTarget: '',
				// Set target to open link in new tab
				typographer: true,
				// Enable some language-neutral replacements + quotes beautification
				quotes: '\u201c\u201d\u2018\u2019',
				// Double + single quotes replacement pairs, when typographer enabled,
				// and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
				highlight: RemarkableUtils.highlightSyntaxInString
				// The syntax highlighter function
			}).use(linkify);
		return RemarkableUtils.ALLOW_HTML_RENDERER_INSTANCE;
	}

	/** Returns a remarkable renderer for findings messages. */
	public static getFindingMessageRenderer(): Remarkable {
		if (RemarkableUtils.FINDING_RENDERER !== null) {
			return RemarkableUtils.FINDING_RENDERER;
		}
		RemarkableUtils.FINDING_RENDERER = RemarkableUtils.createFindingRenderer();
		return RemarkableUtils.FINDING_RENDERER;
	}

	/** Returns a remarkable renderer for findings descriptions. */
	public static getFindingDescriptionRenderer(): Remarkable {
		if (RemarkableUtils.FINDING_RENDERER !== null) {
			return RemarkableUtils.FINDING_RENDERER;
		}
		RemarkableUtils.FINDING_RENDERER = RemarkableUtils.createFindingRenderer();
		return RemarkableUtils.FINDING_RENDERER;
	}

	/** @returns A renderer to use for finding messages or finding descriptions. */
	private static createFindingRenderer(): Remarkable {
		// Autoconvert URL-like texts to links
		return new Remarkable('full', {
			html: true,
			// We have to allow html in finding messages/descriptions as e.g. used by FindBugs uses HTML
			// messages
			xhtmlOut: false,
			// Use '/' to close single tags (&lt;br
			// /&gt;)
			breaks: false,
			// Don't convert '\n' in paragraphs into
			// &lt;br&gt; (GitHub does not do it)
			langPrefix: 'hljs inline-code-container code example language-',
			// CSS language prefix for fenced blocks
			linkTarget: '_blank',
			// Set target to open link in a new tab
			typographer: true,
			// Enable some language-neutral replacements + quotes beautification
			quotes: '\u201c\u201d\u2018\u2019',
			// Double + single quotes replacement pairs, when typographer enabled,
			// and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
			highlight: RemarkableUtils.highlightSyntaxInString
			// The syntax highlighter function
			// remarkable extracts the language parameter from the markup annotation (e.g., java from ```java)
			// if no language is given, the syntax highlighter uses a default highlighting
		}).use(linkify);
	}

	/**
	 * Returns a remarkable renderer for Markdown. Differs from the FindingRenderer by allowing line breaks and not
	 * having a border box.
	 */
	public static getMarkdownRenderer(): Remarkable {
		if (RemarkableUtils.MARKDOWN_RENDERER !== null) {
			return RemarkableUtils.MARKDOWN_RENDERER;
		}
		RemarkableUtils.MARKDOWN_RENDERER = RemarkableUtils.createMarkdownRenderer();
		return RemarkableUtils.MARKDOWN_RENDERER;
	}

	/** @returns A renderer to use for Markdown. */
	private static createMarkdownRenderer(): Remarkable {
		// Autoconvert URL-like texts to links
		return new Remarkable('full', {
			html: true,
			// We have to allow html in finding messages/descriptions as e.g. used by FindBugs uses HTML
			// messages
			xhtmlOut: false,
			// Use '/' to close single tags (&lt;br
			// /&gt;)
			breaks: true,
			// Convert '\n' in paragraphs into
			// &lt;br&gt;
			langPrefix: 'hljs code markdown language-',
			// CSS language prefix for fenced blocks
			linkTarget: '_blank',
			// Set target to open link in a new tab
			typographer: true,
			// Enable some language-neutral replacements + quotes beautification
			quotes: '\u201c\u201d\u2018\u2019',
			// Double + single quotes replacement pairs, when typographer enabled,
			// and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
			highlight: RemarkableUtils.highlightSyntaxInString
			// The syntax highlighter function
			// remarkable extracts the language parameter from the markup annotation (e.g., java from ```java)
			// if no language is given, the syntax highlighter uses a default highlighting
		}).use(linkify);
	}
}
